




import {Component, Vue} from 'vue-property-decorator'
import {userLogin} from '@/api/webchatAuth'

@Component({})
export default class WebChatAuth extends Vue {

  public async created() {
    if (this.$route.query.code && this.$route.query.state) {
      const result = await userLogin(this.$route.query.code, this.$route.query.state)
      if (result.succeeded) {
        localStorage.setItem('Keep_OpenId', result.openId);
      }
      await this.$router.replace('/');
    } else {
      // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
      await this.$router.replace('/');
    }
  }
}
